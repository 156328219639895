import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './home.css';

const Home = () => {
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [selectedTagsForNewTenant, setSelectedTagsForNewTenant] = useState([]);
  const [formPart, setFormPart] = useState(1);
  const [tenantDetail, setTenantDetail] = useState({});
  const [showTags, setShowTags] = useState(false);
  const [tagsToDelete, setTagsToDelete] = useState([]);
  const [showDeleteTags, setShowDeleteTags] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [globalSelectedTags, setGlobalSelectedTags] = useState([]);
  const [editSelectedTags, setEditSelectedTags] = useState([]);
  const [newTags, setNewTags] = useState('');
  const [tenants, setTenants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editTenantId, setEditTenantId] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);
  const [editSection, setEditSection] = useState('info');
  const [newTenant, setNewTenant] = useState({
    name: '',
    instagram: '',
    whatsapp: '',
    category: [],
    domisili: '',
    nama_cp: '',
    makanan: ['']
  });

  useEffect(() => {
    fetchTenants();
  }, [category, globalSelectedTags]); // Trigger fetchTenants when category or globalSelectedTags changes

  useEffect(() => {
    fetchTags();
  }, []);

  const handleEditSectionChange = (e) => {
    setEditSection(e.target.value);
  };

  const handleToggleTags = () => {
    setShowTags((prevShowTags) => !prevShowTags);
    if (showDeleteTags) {
      setShowDeleteTags(false);
    }
  };

  const handleShowDetail = (tenant) => {
    setTenantDetail(tenant);
    setShowDetailPopup(true);
  };

  const handleCloseDetailPopup = () => {
    setShowDetailPopup(false);
    setTenantDetail({});
  };

  const handleNextPart = () => {
    if (formPart < 2) {
      setFormPart(formPart + 1);
    }
  };

  const handlePreviousPart = () => {
    if (formPart > 1) {
      setFormPart(formPart - 1);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get('http://apit.chawnima.id/api/tags');
      setAllTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleTagButtonClick = (tagName) => {
    if (isEditing) {
      setEditSelectedTags((prevState) => {
        if (prevState.includes(tagName)) {
          return prevState.filter((name) => name !== tagName);
        } else {
          return [...new Set([...prevState, tagName])];
        }
      });
    } else if (showAddForm) {
      setSelectedTagsForNewTenant((prevState) => {
        if (prevState.includes(tagName)) {
          return prevState.filter((name) => name !== tagName);
        } else {
          return [...new Set([...prevState, tagName])];
        }
      });
    } else {
      setGlobalSelectedTags((prevState) => {
        if (prevState.includes(tagName)) {
          return prevState.filter((name) => name !== tagName);
        } else {
          return [...new Set([...prevState, tagName])];
        }
      });
    }
  };
  
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!newTenant.name) {
      newErrors.name = 'masih kosong woi';
    }

    if (!newTenant.instagram) {
      newErrors.instagram = 'masih kosong woi';
    }

    if (!newTenant.whatsapp) {
      newErrors.whatsapp = 'masih kosong woi';
    } else if (!/^\d+$/.test(newTenant.whatsapp)) {
      newErrors.whatsapp = 'Angka bang';
    }

    if (!newTenant.category.length) {
      newErrors.category = 'milih kategori lok';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fetchTenants = async () => {
    const params = {
      search: searchTerm,
      category: category,
      tags: globalSelectedTags.join(','),
    };
    try {
      const response = await axios.get('http://apit.chawnima.id/api/tenants', { params });
      const tenantsWithParsedMakanan = response.data.map(tenant => {
        return {
          ...tenant,
          makanan: Array.isArray(tenant.makanan) ? tenant.makanan : JSON.parse(tenant.makanan),
          tags: Array.isArray(tenant.tags) ? tenant.tags : tenant.tags.split(',')
        };
      });
      setTenants(tenantsWithParsedMakanan);
    } catch (error) {
      console.error('Error fetching tenants:', error);
    }
  };

  const handleSearch = async () => {
    fetchTenants(); // Trigger fetchTenants on search
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://apit.chawnima.id/api/tenants/${id}`);
      fetchTenants();
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Error deleting tenant:', error);
    }
  };

  const handleDeleteTag = async () => {
    try {
      const response = await axios.delete('http://apit.chawnima.id/api/tags', { data: { tags: tagsToDelete } });

      setAllTags(allTags.filter(tag => !tagsToDelete.includes(tag.name)));
      setTagsToDelete([]);
      fetchTenants();
    } catch (error) {
      console.error('Error deleting tags:', error);
    }
  };

  const handleTagDeleteSelection = (tagName) => {
    setTagsToDelete((prevState) =>
      prevState.includes(tagName)
        ? prevState.filter((name) => name !== tagName)
        : [...prevState, tagName]
    );
  };

  const handleShowDeleteConfirm = (tenant) => {
    setTenantToDelete(tenant);
    setShowDeleteConfirm(true);
  };

  const handleToggleDeleteTags = () => {
    setShowDeleteTags((prevShowDeleteTags) => !prevShowDeleteTags);
    if (showTags) {
      setShowTags(false);
    }
  };

  const handleAddTenant = async () => {
    if (!validateForm()) {
      return;
    }
  
    const tenantData = {
      ...newTenant,
      category: newTenant.category.join(','),
      makanan: JSON.stringify(newTenant.makanan),
      tags: [...new Set(isEditing ? editSelectedTags : selectedTagsForNewTenant)] 
    };
  
    try {
      if (newTags) {
        const tagsArray = newTags.split(',').map(tag => tag.trim());
        for (const tag of tagsArray) {
          const response = await axios.post('http://apit.chawnima.id/api/tags', { name: tag });
  
          if (isEditing) {
            setEditSelectedTags(prevTags => [...new Set([...prevTags, tag])]);
          } else {
            setSelectedTagsForNewTenant(prevTags => [...new Set([...prevTags, tag])]);
          }
          setAllTags(prevTags => [...new Set([...prevTags, { id: response.data.id, name: tag }])]);
        }
  
        tenantData.tags = [...new Set([...tenantData.tags, ...tagsArray])];
      }
  
      const response = isEditing
        ? await axios.put(`http://apit.chawnima.id/api/tenants/${editTenantId}`, tenantData)
        : await axios.post('http://apit.chawnima.id/api/tenants', tenantData);
  
      if (response.data.id) {
        const tenantId = response.data.id;
        const tagIds = await getTagIdsFromNames(tenantData.tags); // Ensure all tags are in the database
        await assignTagsToTenant(tenantId, tagIds);
      }
  
      setNewTenant({
        name: '',
        instagram: '',
        whatsapp: '',
        category: [],
        domisili: '',
        nama_cp: '',
        makanan: ['']
      });
      
      setNewTags('');
      setSelectedTagsForNewTenant([]);
      setEditSelectedTags([]);
      setShowAddForm(false);
      setIsEditing(false);
      fetchTenants();
    } catch (error) {
      console.error('Error saving tenant:', error);
    }
  };
  

  const handleShowAddForm = () => {
    setNewTenant({
      name: '',
      instagram: '',
      whatsapp: '',
      category: [],
      domisili: '',
      nama_cp: '',
      makanan: ['']
    });

    setIsEditing(false);
    setErrors({});
    setShowAddForm(true);
  };

  const handleEdit = (tenant) => {
    setNewTenant({
      name: tenant.name,
      instagram: tenant.instagram,
      whatsapp: tenant.whatsapp,
      category: tenant.category ? tenant.category.split(',') : [],
      domisili: tenant.domisili,
      nama_cp: tenant.nama_cp,
      makanan: Array.isArray(tenant.makanan) ? tenant.makanan : JSON.parse(tenant.makanan)
    });
    setEditSelectedTags(tenant.tags ? (typeof tenant.tags === 'string' ? tenant.tags.split(',') : tenant.tags) : []);
    setEditTenantId(tenant.id);
    setIsEditing(true);
    setShowAddForm(true);
    setErrors({});
    setShowTags(true);
    setEditSection('info'); // Reset to default section
  };
  
const getTagIdsFromNames = async (tagNames) => {
  try {
    const response = await axios.get('http://apit.chawnima.id/api/tags');
    const tags = response.data.filter(tag => tagNames.includes(tag.name));
    return tags.map(tag => tag.id);
  } catch (error) {
    console.error('Error fetching tag IDs:', error);
    return [];
  }
};

  const assignTagsToTenant = async (tenantId, tagIds) => {
    try {
      const promises = tagIds.map(tagId => axios.post('http://apit.chawnima.id/api/tenant_tags', { tenant_id: tenantId, tag_id: tagId }));
      await Promise.all(promises);
    } catch (error) {
      console.error('Error assigning tags to tenant:', error);
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setNewTenant(prevState => {
      const newCategory = checked
        ? [...prevState.category, value]
        : prevState.category.filter(category => category !== value);
      return { ...prevState, category: newCategory };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTenant(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddMakanan = () => {
    setNewTenant(prevState => ({
      ...prevState,
      makanan: [...prevState.makanan, '']
    }));
  };

  const handleRemoveMakanan = (index) => {
    setNewTenant(prevState => ({
      ...prevState,
      makanan: prevState.makanan.filter((_, i) => i !== index)
    }));
  };

  const handleMakananChange = (index, value) => {
    const newMakanan = [...newTenant.makanan];
    newMakanan[index] = value;
    setNewTenant(prevState => ({
      ...prevState,
      makanan: newMakanan
    }));
  };

  return (
    <div className="Home">

      <div className="filter-search-container">
        <div className="filter-container">
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="">All</option>
            <option value="makanan berat">Makanan Berat</option>
            <option value="makanan ringan">Makanan Ringan</option>
            <option value="dessert">Dessert</option>
            <option value="minuman">Minuman</option>
          </select>

          <button className="toggle-tags" onClick={handleToggleTags}>
            # Tags
          </button>

          <button className='toggle-tags-del' onClick={handleToggleDeleteTags}>Delete Tags</button>
        </div>

        <div className="search-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Search by name, makanan, or domisili..."
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>

      {showTags && !showDeleteTags && (
        <div className="tag-container">
        <div className="fill-container">
          {allTags.length > 0 ? (
            allTags.map((tag) => (
              <button
                key={tag.id}
                className={`tag-button ${isEditing ? editSelectedTags.includes(tag.name) ? 'selected' : '' : showAddForm ? selectedTagsForNewTenant.includes(tag.name) ? 'selected' : '' : globalSelectedTags.includes(tag.name) ? 'selected' : ''}`}
                onClick={() => handleTagButtonClick(tag.name)}
              >
                # {tag.name}
              </button>
            ))
          ) : (
            <p style={{ color: '#A9A9A9', fontStyle: 'italic' }}>No tags available. Add new tags below.</p>
          )}
        </div>
    </div>
      )}

      {showDeleteTags && !showTags && (
        <div className="tag-container">
          <div className="fill-container">
            {allTags.length > 0 ? (
              allTags.map((tag) => (
                <button
                  key={tag.id}
                  className={`tag-button ${tagsToDelete.includes(tag.name) ? 'selected' : ''}`}
                  onClick={() => handleTagDeleteSelection(tag.name)}
                >
                  # {tag.name}
                </button>
              ))
            ) : (
              <p style={{ color: '#A9A9A9', fontStyle: 'italic' }}>No tags available. Add new tags below.</p>
            )}
          </div>
          <button className="confirm-delete" onClick={handleDeleteTag}>Delete Tags</button>
        </div>
      )}

      <button className="add" onClick={handleShowAddForm}>Add Tenant bro</button>

      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Tenant</th>
            <th>No WhatsApp</th>
            <th>Category</th>
            <th>Makanan</th>
            <th>Tags</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant, index) => (
            <tr key={tenant.id}>
              <td>{index + 1}</td>
              <td>{tenant.name}</td>
              <td>{tenant.whatsapp}</td>
              <td>{tenant.category}</td>
              <td>{Array.isArray(tenant.makanan) ? tenant.makanan.join(', ') : tenant.makanan}</td>
              <td>{Array.isArray(tenant.tags) ? tenant.tags.join(', ') : tenant.tags}</td>
              <td className="oper">
                <button className='detil' onClick={() => handleShowDetail(tenant)}>Detail</button>
                <button className="edit" onClick={() => handleEdit(tenant)}>Edit</button>
                <button className="delete" onClick={() => handleShowDeleteConfirm(tenant)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDetailPopup && (
        <div className="overlay">
          <div className="detail-popup">
            <h2>Detail Tenant</h2>
            <p><strong>Domisili:</strong> {tenantDetail.domisili}</p>
            <p><strong>Nama CP:</strong> {tenantDetail.nama_cp}</p>
            <p><strong>Instagram:</strong> {tenantDetail.instagram}</p>
            <button className="close" onClick={handleCloseDetailPopup}>Close</button>
          </div>
        </div>
      )}

{showAddForm && (
  <div className="overlay">
    <div className="add-form">
      <h2>{isEditing ? 'Edit Tenant' : 'Add New Tenant'}</h2>

      {!isEditing && formPart === 1 && (
        <>
          <input
            type="text"
            name="name"
            value={newTenant.name}
            onChange={handleChange}
            placeholder="Nama Tenant"
          />
          {errors.name && <p className="error">{errors.name}</p>}

          <input
            type="text"
            name="instagram"
            value={newTenant.instagram}
            onChange={handleChange}
            placeholder="Instagram"
          />
          {errors.instagram && <p className="error">{errors.instagram}</p>}

          <input
            type="text"
            name="whatsapp"
            value={newTenant.whatsapp}
            onChange={handleChange}
            placeholder="No WhatsApp"
          />
          {errors.whatsapp && <p className="error">{errors.whatsapp}</p>}

          <input
            type="text"
            name="domisili"
            value={newTenant.domisili}
            onChange={handleChange}
            placeholder="Domisili"
          />
          {errors.domisili && <p className="error">{errors.domisili}</p>}

          <input
            type="text"
            name="nama_cp"
            value={newTenant.nama_cp}
            onChange={handleChange}
            placeholder="Nama CP"
          />
          {errors.nama_cp && <p className="error">{errors.nama_cp}</p>}

          <div className="dropdown">
            <button className="dropbtn">Select Categories</button>
            <div className="dropdown-content">
              <label>
                Makanan Berat
                <input
                  type="checkbox"
                  value="makanan berat"
                  checked={newTenant.category.includes('makanan berat')}
                  onChange={handleCategoryChange}
                />
              </label>
              <label>
                Makanan Ringan
                <input
                  type="checkbox"
                  value="makanan ringan"
                  checked={newTenant.category.includes('makanan ringan')}
                  onChange={handleCategoryChange}
                />
              </label>
              <label>
                Dessert
                <input
                  type="checkbox"
                  value="dessert"
                  checked={newTenant.category.includes('dessert')}
                  onChange={handleCategoryChange}
                />
              </label>
              <label>
                Minuman
                <input
                  type="checkbox"
                  value="minuman"
                  checked={newTenant.category.includes('minuman')}
                  onChange={handleCategoryChange}
                />
              </label>
            </div>
          </div>
          {errors.category && <p className="error">{errors.category}</p>}

          <div className="makanan-container">
            {newTenant.makanan.map((makanan, index) => (
              <div key={index} className="makanan-field">
                <input
                  type="text"
                  value={makanan}
                  onChange={(e) => handleMakananChange(index, e.target.value)}
                  placeholder="Makanan"
                />
                <button className="remove-tombol" type="button" onClick={() => handleRemoveMakanan(index)}>Remove</button>
              </div>
            ))}
            <button className="add-makanan" type="button" onClick={handleAddMakanan}>Add Makanan</button>
          </div>
          {errors.makanan && <p className="error">{errors.makanan}</p>}

          <button className="cancel" onClick={() => { setShowAddForm(false); setIsEditing(false); setNewTenant({ name: '', instagram: '', whatsapp: '', category: [], domisili: '', nama_cp: '', makanan: [''] }); }}>Cancel</button>
          <button className="next" onClick={handleNextPart}>Next</button>
        </>
      )}

      {!isEditing && formPart === 2 && (
        <>
          <div className="tag-container">
            <h3>Tags</h3>
            <div className="fill-container">
              {allTags.length > 0 ? (
                allTags.map((tag) => (
                  <button
                    key={tag.id}
                    className={`tag-button ${selectedTagsForNewTenant.includes(tag.name) ? 'selected' : ''}`}
                    onClick={() => handleTagButtonClick(tag.name)}
                  >
                    # {tag.name}
                  </button>
                ))
              ) : (
                <p style={{ color: '#A9A9A9', fontStyle: 'italic' }}>No tags available. Add new tags below.</p>
              )}
            </div>
            <input
              type="text"
              value={newTags}
              onChange={(e) => setNewTags(e.target.value)}
              placeholder="Add new Tags (separated by commas)"
            />
          </div>

          <button className="back" onClick={handlePreviousPart}>Back</button>
          <button className="sep" onClick={handleAddTenant}>Save</button>
        </>
      )}

      {isEditing && (
        <>
          <div className="edit-section-tabs">
            <button
              className={`tab-button ${editSection === 'info' ? 'active' : ''}`}
              onClick={() => setEditSection('info')}
            >
              Information
            </button>
            <button
              className={`tab-button ${editSection === 'category' ? 'active' : ''}`}
              onClick={() => setEditSection('category')}
            >
              Category
            </button>
            <button
              className={`tab-button ${editSection === 'menu' ? 'active' : ''}`}
              onClick={() => setEditSection('menu')}
            >
              Menu
            </button>
            <button
              className={`tab-button ${editSection === 'tags' ? 'active' : ''}`}
              onClick={() => setEditSection('tags')}
            >
              Tags
            </button>
          </div>

          <div className="edit-section-content">
            {editSection === 'info' && (
              <div className="tab-content">
                <input
                  type="text"
                  name="name"
                  value={newTenant.name}
                  onChange={handleChange}
                  placeholder="Nama Tenant"
                />
                {errors.name && <p className="error">{errors.name}</p>}

                <input
                  type="text"
                  name="instagram"
                  value={newTenant.instagram}
                  onChange={handleChange}
                  placeholder="Instagram"
                />
                {errors.instagram && <p className="error">{errors.instagram}</p>}

                <input
                  type="text"
                  name="whatsapp"
                  value={newTenant.whatsapp}
                  onChange={handleChange}
                  placeholder="No WhatsApp"
                />
                {errors.whatsapp && <p className="error">{errors.whatsapp}</p>}

                <input
                  type="text"
                  name="domisili"
                  value={newTenant.domisili}
                  onChange={handleChange}
                  placeholder="Domisili"
                />
                {errors.domisili && <p className="error">{errors.domisili}</p>}

                <input
                  type="text"
                  name="nama_cp"
                  value={newTenant.nama_cp}
                  onChange={handleChange}
                  placeholder="Nama CP"
                />
                {errors.nama_cp && <p className="error">{errors.nama_cp}</p>}
              </div>
            )}

            {editSection === 'category' && (
              <div className="tab-content">
                <div className="dropdown">
                  <button className="dropbtn">Select Categories</button>
                  <div className="dropdown-content">
                    <label>
                      Makanan Berat
                      <input
                        type="checkbox"
                        value="makanan berat"
                        checked={newTenant.category.includes('makanan berat')}
                        onChange={handleCategoryChange}
                      />
                    </label>
                    <label>
                      Makanan Ringan
                      <input
                        type="checkbox"
                        value="makanan ringan"
                        checked={newTenant.category.includes('makanan ringan')}
                        onChange={handleCategoryChange}
                      />
                    </label>
                    <label>
                      Dessert
                      <input
                        type="checkbox"
                        value="dessert"
                        checked={newTenant.category.includes('dessert')}
                        onChange={handleCategoryChange}
                      />
                    </label>
                    <label>
                      Minuman
                      <input
                        type="checkbox"
                        value="minuman"
                        checked={newTenant.category.includes('minuman')}
                        onChange={handleCategoryChange}
                      />
                    </label>
                  </div>
                </div>
                {errors.category && <p className="error">{errors.category}</p>}
              </div>
            )}

            {editSection === 'menu' && (
              <div className="tab-content menu-tab-content">
                <div className="makanan-container">
                  {newTenant.makanan.map((makanan, index) => (
                    <div key={index} className="makanan-field">
                      <input
                        type="text"
                        value={makanan}
                        onChange={(e) => handleMakananChange(index, e.target.value)}
                        placeholder="Makanan"
                      />
                      <button className="remove-tombol" type="button" onClick={() => handleRemoveMakanan(index)}>Remove</button>
                    </div>
                  ))}
                  <button className="add-makanan" type="button" onClick={handleAddMakanan}>Add Makanan</button>
                </div>
                {errors.makanan && <p className="error">{errors.makanan}</p>}
              </div>
            )}

            {editSection === 'tags' && (
              <div className="tab-content">
                <div className="tag-container">
                  <h3>Tags</h3>
                  <div className="fill-container">
                    {allTags.length > 0 ? (
                      allTags.map((tag) => (
                        <button
                          key={tag.id}
                          className={`tag-button ${editSelectedTags.includes(tag.name) ? 'selected' : ''}`}
                          onClick={() => handleTagButtonClick(tag.name)}
                        >
                          # {tag.name}
                        </button>
                      ))
                    ) : (
                      <p style={{ color: '#A9A9A9', fontStyle: 'italic' }}>No tags available. Add new tags below.</p>
                    )}
                  </div>
                  <input
                    type="text"
                    value={newTags}
                    onChange={(e) => setNewTags(e.target.value)}
                    placeholder="Add new Tags (separated by commas)"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="button-group">
            <button className="cancel" onClick={() => { setShowAddForm(false); setIsEditing(false); setNewTenant({ name: '', instagram: '', whatsapp: '', category: [], domisili: '', nama_cp: '', makanan: [''] }); }}>Cancel</button>
            <button className="sep" onClick={handleAddTenant}>Save</button>
          </div>
        </>
      )}
    </div>
  </div>
)}

      {showDeleteConfirm && (
        <div className="overlay">
          <div className="confirm-delete-form">
            <h2>Are you sure you want to delete this tenant?</h2>
            <p>{tenantToDelete.name}</p>
            <button className="sep" onClick={() => handleDelete(tenantToDelete.id)}>Yes, Delete</button>
            <button className="cancel" onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
