import React from 'react'
import './app.css'
import Home from './components/Home/Home'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

const App = () =>{
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
      </Routes>
    </Router>
    </>
  );
}

export default App